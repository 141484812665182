import React from 'react'
import './ProjectItem.css'

const ProjectItem = ({profilePic, title, date, description, click}) => (
    <div className='animation-container' onClick={click}>
        <div className='container'>
            <div className='picture-box'>
                <img className='profile-pic' src={profilePic} alt='oops all text'/>
            </div>
            <div className='name-date'>
                <h3>{title}</h3>
                <p>{date}</p>
            </div>
            <div className='description'>
                <p>{description}</p>
            </div>
        </div>
    </div>
)

export default ProjectItem