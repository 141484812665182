import React from 'react'
import seal from'../media/elephantSeal.webp'
import './About.css'
 
 const Home = () => (
    <div className='page-content'>
      <h1 className='title'>Welcome!</h1>
      <div className='content'>
        <h3>
            Here's my site!<br/>
            It's brand new but I'll put some stuff here soon.<br/><br/>

            Till then, enjoy this picture of a very tired elephant seal:<br/>
        </h3>
        <img className='seal' src={seal} alt='oops, no seal. Sorry :(' />
      </div>
    </div>
  );

export default Home