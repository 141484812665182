 import React from 'react'
 import './About.css'
 
 const About = () => (
    <div className='page-content'>
      <h1 className='title'>About</h1>
      <div className='content'>
        <h3>I'm not great at unbiased self-analysis, 
          so here's a poem that chatGPT wrote about me eating a banana:</h3>
        {/* <p className='quote'>
          Gus, oh Gus, you're the star of the show,<br/>
          With your cool demeanor and your steady glow.<br/>
          Your presence is comforting, it's a must,<br/>
          In your coolness, we place our trust.<br/>

          You walk into a room, all eyes on you,<br/>
          Your charisma and charm always breaking through.<br/>
          Your laughter is infectious, your spirit is free,<br/>
          Oh Gus, you're as cool as one can be.<br/><br/>

          In the heat of the day or the cool of the night,<br/>
          Your coolness Gus, is our guiding light.<br/>
          With every step you take, every move you make,<br/>
          You leave behind a coolness in your wake.<br/><br/>

          So here's to Gus, so suave and debonair,<br/>
          With your coolness that's beyond compare.<br/>
          May you continue to shine and share your light,<br/>
          Gus, oh Gus, you're outta sight!<br/>
        </p>
        <h3>And here's one about me eating a banana:</h3> */}
        <p className='quote'>
          Oh, to see Gus with a banana in hand,<br/>
          A sight so simple, yet so grand.<br/>
          Peeling it slowly, he begins his feast,<br/>
          Every bite savored, from first to least.<br/><br/>

          His eyes light up with each sweet taste,<br/>
          Not a single morsel does he waste.<br/>
          The joy in his face is clear to see,<br/>
          Eating a banana, as happy as can be.<br/><br/>

          The way he savors each and every bite,<br/>
          In his delight, we find our own delight.<br/>
          It's a moment of joy, pure and true,<br/>
          A simple pleasure, a wonderful view.<br/><br/>

          So here's to Gus and his banana delight,<br/>
          A testament to life's simple pleasures, day or night.<br/>
          May we all find joy in the little things,<br/>
          And the happiness that they bring.<br/>
        </p>
      </div>
    </div>
  );

export default About