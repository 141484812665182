import React from 'react'
import './About.css'
 
const Contact = () => (


   <div className="page-content">
     <h1 className='title'>Contact</h1>
     <div className='content'>
      <iframe style={{alignSelf:"center"}} src="https://docs.google.com/forms/d/e/1FAIpQLSe7uWM1bRmz8_WoFqH5l479uIDEvfYHXcX_CtM8Aoel_gVi2g/viewform?embedded=true" title="Contact Form" width="640" height="561" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
     </div>
   </div>
 );

export default Contact