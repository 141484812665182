import { React, useState } from 'react'
import './App.css'
// import Menu from './components/Menu'
import Home from './components/Home'
import Projects from './components/Projects'
import Music from './components/Music'
import Navbar from './components/Navbar'
import About from './components/About'
import Contact from './components/Contact'
// import Blog from './components/Blog'
import PageContent from './components/PageContent'
import { Route, Routes } from 'react-router-dom'
import test from './articles/Test File.txt'
// import articles from './post-mgr.json'



function App () {

  // console.log(articles)
  // console.log(articles[0])
  // console.log(Object.keys(articles).length)

  // const [currentPage, setCurrentPage] = useState('home')
  const [post, loadPost] = useState({
    title: 'Pick Something PLZ',
    text: 'Welcome to my site!'
  })

  const loadFile = async () => {
    //replace "test" below with the uri of the article you want to load
    await fetch(test)
    .then((r) => r.text())
    .then(text  => {
      let postData = {text: text, title: 'Test File'}
      loadPost(postData)
    })  
  }

  return(
    <>
    <div>
      <Navbar/>
        <div className="App">
          <div className='left-column'>
            {/* <Menu blog={loadFile}/> */}
          </div>
          <div className='center-column'>
            <Routes>
              <Route path="/" element={<Home/>} />
              <Route path="/projects" element={<Projects/>} />
              <Route path="/music" element={<Music/>} />
              <Route path="/about" element={<About/>} />
              <Route path="/blog" clicked={loadFile} element={<PageContent post={post}/>} />
              <Route path="/contact" element={<Contact/>} />
            </Routes> 
          </div>
          <div className='right-column'>
          </div>
        </div>
    </div>
    </>
  )
}

export default App;

//<h1 style={{padding:'0px 20px'}}>Hello!</h1>  << Your old title



/*                            Chat GPT 1st Attempt*/
// // src/App.js
// import React from 'react';
// import './App.css';
// import Menu from './components/Menu';
// import PageContent from './components/PageContent';

// function App() {
//   return (
//     <div className="App">
//       <Menu />
//       <PageContent />
//     </div>
//   );
// }

// export default App;