import React from 'react'
import './ClassProject.css'
import megaBrain from '../media/coll101/MegaBrain.png'
import calendar from '../media/coll101/calendar.png'
import victor from '../media/coll101/victorProjectFlow.png'
// yt shorts original size: 398x707 h/w = 1.776
const ClassProject = () => (
    
    <>
      
      <div className='cp-container'>
      <h1>The Problem:</h1>
        <div className='slide' style={{justifyContent:'space-evenly'}}>
            <div className='yt-short short'>
              <iframe className='short' src="https://www.youtube.com/embed/1gxNLiN_QqI" title="Time blindness #adhd" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
            </div>
            <div className='cp-description'>
              <h2>Relatable</h2>
              <h3>
                I also have adhd and as a result, I have a severely warped sense of time.<br/><br/>

                Learning how to manage this has involved revolutionizing the way I<br/> 
                organize my time. That's my main focus today.<br/><br/>
              </h3>
              <br/><br/><br/>
              <div className='abbey-quote'>
                "Gus did not do his homework,<br/>
                but he did build a whole website<br/>
                when he opened his computer to do<br/>
                his homework"<br/><br/>
                - Abbey
              </div>
            </div>
        </div>

        <div className='slide' style={{flexDirection:'column'}}>
        <h1 style={{margin:'0'}}>The Solution:</h1>
        <h1 style={{alignSelf:'center'}}>Mega Brain</h1>
            <div style={{borderRadius:"1em", overflow:"hidden", width:"60em"}}>
              <img src={megaBrain} style={{width:"60em"}} alt='this was supposed to be a screenshot'/>
            </div>
            <div className='cp-description' style={{alignItems:'center'}}>
              <h3>This is how I acted on what I learned!</h3>
              <a href='https://youtu.be/jUEfWLQrTOk?si=LjdKxmTfPuGuzRnB' >Dig in - Struthless video</a>
              <a href='https://youtu.be/rDdoUbCFn24?si=S9fP4q9aviF3FxAP'>"Reducing Friction" Diary of a CEO - Nir Eyal</a>
            </div>
        </div>

        <div className='slide' style={{flexDirection:'column'}}>
          <h1>Scheduling and Prioritizing:</h1>
          <div className='scheduling-container'>
            <div className='schedule-left'>
                  {/* <h3>Timeblindness - HowtoADHD</h3> */}
                  <div className='short-row'>
                    <div className='yt-short small-short' >
                      <iframe className='small-short' src="https://www.youtube.com/embed/5uIxl73pufI" title="ADHD Tools to Help with Timeblindness #adhd #shorts" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                    </div>
                    <div className='cp-description' style={{flexGrow:'1'}}>
                      <h2 >Timeblindness</h2>
                      <h3>
                        <ul>
                          <li>Make time visible</li><br/>
                          <li>Track how long <br/> things actually take</li><br/>
                          <li>Double or triple <br/> your estimates</li>
                        </ul>
                      </h3>
                    </div>
                  </div>
                  {/* <h3>Effort/ Impact matrix</h3> */}
                  <div className='short-row'>
                    <div className='yt-short small-short'>
                      <iframe className='small-short' src="https://www.youtube.com/embed/pdBu-Rr5ckQ" title="How to Prioritize Your Work: Effort Impact Scale" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                    </div>
                    <div className='cp-description' style={{flexGrow:'1'}}>
                        <h2>Effort/ Impact <br/> Chart</h2>
                        <h3>
                          Helpful technique<br/>
                          for choosing what<br/>
                          to act on.
                        </h3>
                    </div>
                  </div>
                  {/* <h3>Binary comparison/ Eisenhower matrix. a.k.a Urgency/ Flexibility.</h3> */}
                  <div className='short-row'>
                    <div className='yt-short small-short'>
                      <iframe className='small-short' src="https://www.youtube.com/embed/4pIEAdEyr_0" title="Struggling to Prioritize? Try These Simple Tips  | #shorts" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                    </div>
                    <div className='cp-description' style={{flexGrow:'1'}}>
                        <h2>Binary Comparison</h2>
                        <h3>
                          Helpful technique<br/>
                          for making decisions.
                        </h3>
                    </div>
                  </div>
            </div>
            <div className='schedule-right'>
                  <iframe className='yt-short' width="560" height="315" src="https://www.youtube.com/embed/8G4Q5dw3aQo?si=CDp8r98nz_SYM9xe" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                  <div className='cp-description'>
                    <h2>Time blocking + urgency/ flexibility</h2>
                    <h3>
                      This is a very interesting take on time boxing.<br/><br/>
                      I've been using time boxing for the last few weeks and it's very<br/>
                      helpful for me. The way she integrates an assessment of urgency<br/>
                      and flexibility to give herself room to make changes in the <br/>
                      moment without disrupting her whole schedule is brilliant, and <br/>
                      is something I'm working on integrating.
                    </h3>
                  </div>
            </div>
          </div>
        </div>

        <div className='slide' style={{flexDirection:'column'}}>
          <h1>A New Hope:</h1>
          <div style={{borderRadius:"1em", overflow:"hidden", width:"60em"}}>
              <img src={calendar} style={{width:"60em"}} alt='this was supposed to be a calendar'/>
          </div>
          <h3>
            Each section in this calendar corresponds to a category in my main Google Sheet.
          </h3>
        </div>

        <div className='slide' style={{flexDirection:'column'}}>
          <h1>Friends:</h1>
          <div style={{display:'flex'}}>
            <div style={{display:'flex', flexDirection:'column', alignItems:'center', width:'100%'}}>
              <div style={{borderRadius:"1em", width:'100%', overflow:"hidden"}}>
                  <img src={victor} style={{width:"100%"}} alt='this was supposed to be a calendar'/>
              </div>
              <h3 style={{width:'60%'}}>
                My brother and I use this chart to manage the work we do on
                one of my Dad's properties. It's the same chart I use to manage
                my own personal projects, and it's integrated into my master
                spreadsheet. It's basically a kanban board I built into my
                organizational system.
              </h3>
            </div>
          </div>
        </div>

        <div className='slide' style={{flexDirection:'column'}}>
          <div className='abbey-quote'>
            "Now he has a plan<br/>
            to stay focused<br/>
            on his homework!"<br/><br/>
            - Abbey
          </div>
        </div>

              

        <div className='slide' style={{flexDirection:'column'}}>
          <h1 style={{marginBottom:'0px'}}>Benefits:</h1>
          <h1 style={{fontSize:'1.3em', marginTop:'0px'}}>(Takeaways)</h1>
          <div style={{display:'flex'}}>
            <div style={{display:'flex', flexDirection:'column', width:'50%'}}>
              <h1 style={{fontSize:'1.3em'}}>Time Management</h1>
              <h3 style={{fontSize:'1.1em'}}>
                <ol>
                  <li>I feel less stressed and anxious when I can see what’s coming.</li><br/>
                  <li>I get more of what I want when I do what I don’t want to do (focusing on long-term satisfaction vs short-term pleasure).</li><br/>
                  <li>A huge portion of my self-esteem comes from keeping promises I’ve made to myself, and keeping those promises requires making the time to keep them.</li><br/>
                </ol>
              </h3>
            </div>
            <div style={{display:'flex', flexDirection:'column', width:'50%'}}>
              <h1 style={{fontSize:'1.3em'}}>Study Skills</h1>
              <h3 style={{fontSize:'1.1em'}}>
                <ol>
                  <li>It takes a lot more time to learn something than I realized. Scheduling time specifically for studying course material is important.</li><br/>
                  <li>I had a bad attitude about school before. I thought it was pointless and that real learning happens outside of the classroom. In reality, a lot of thought goes into creating these curricula (most of the time), and through reading the rubrics and completing the assignments as-prescribed, I’ve learned more than I expected to.</li><br/>
                </ol>
              </h3>
            </div>
          </div>
          <h1 style={{fontSize:'1.3em', marginTop:'0px'}}>I  absolutely recommend these techniques to anyone struggling with forgetfulness and inattentiveness.</h1>
        </div>

        <div className='slide' style={{flexDirection:'column'}}>
          <h1>Time Spent:</h1>
          <h1 style={{fontSize:'1.3em'}}>
            <ul>
              <li>Researching: 6-8hrs</li>
              <li>Acting on: 20+ hours (every day so far since we started)</li>
              <li>Sharing: 4hrs with my brother, 1hr with Abbey</li>
              <li>Reflecting: 6-8hrs</li>
            </ul>
          </h1>
        </div>

        <div className='slide' style={{flexDirection:'column'}}>
          <h1>Thanks!</h1>
        </div>
      </div>
    </>
  )

  const temporaryHoldingBay = () =>{
    <>
      <h1>The Solution:</h1>
        <h2>Study skills:</h2>
        {/* Insert picture of coll101 brain */}
        <h3>
          For me, time management and study skills are inseparable. I'm extremely curious about everything, and<br/>
          acquiring knowledge is, fortunately, not a problem for me. The struggle I face with school is making<br/>
          sure my attention stays on the things I've committed myself to learning.
        </h3>
        <h2>Mega Brain:</h2>
        {/* Insert picture of coll101 brain */}
        <h3>
          This is my master google sheet for this course.<br/><br/>
          I have another for my math class, and another for my personal life.<br/>
        </h3>
        {/* Insert picture of inbox column on left */}
        <h3>
          The inbox column really accomodates forgetfulness. It allows me to dump anything I'm thinking about<br/>
          and come back to it later. I usually end up deleting most of it anyway, but it helps reduce the<br/>
          sense of urgency around distracting thoughts, and it does often catch things I might have otherwise<br/>
          forgotten.

          It's a place I can immediately record important dates to add to my calendar later. It also allows me 
          to dump any distracting thoughts that seem urgent and stay committed to what I told myself I'd do in 
          each moment. 
          
          It helps with time management by keeping me from getting distracted and wasting time on unhelpful things,
          and gives me a way to quickly remember important things I'd otherwise forget.
        </h3>
        {/* Insert picture of important dates, resources */}
        <h3>
          The important dates columns should be self-explanatory. It's a schedule.

          It really helps me to keep everything in one place, though. Opening various windows to look for a reminder
          of what I'm supposed to be doing is a recipe for several wasted hours.

          It also really helps that this schedule is dedicated to just one subject. When I sit down to do homework for
          this class, I'm not suddenly thinking about all of the other commitments I have coming up. 
          Another detour avoided!

          The resources column helps save time in the same way. When I go to open an old assignment or start a new one,
          it all happens in one place. This way, I'm not tempted to open a personal project to just "quickly remind 
          myself how it's coming along".

          Again, for the exact same reason, I also include links to Canvas modules for each class. That way, there is
          nothing between me and the work I'm supposed to be doing while I'm using this sheet.
        </h3>
        {/* To-do's column image */}
        <h3>
          The to-do's column is where I write my list for the day. When I sit down, I fill this column out with everything
          I need to do, and I don't stop until those things are done. I tend to forget what I was working on frequently, 
          so this is a real life-saver.
        </h3>
        {/* image of assignments schedule */}
        <h3>
          Last but not least, we have the assignments schedule.

          I copy all assignments with due dates and times alongside a checklist column. I double-check this for accuracy once
          per week, and it's already saved my grade several times in each of the two classes I'm taking.
        </h3>
    </>
  }

  export default ClassProject