import React from 'react'
 import './About.css'
 
 const Music = () => (
    <div className='page-content'>
      <h1 className='title'>Music</h1>
      <div className='content'>
        <h3>Check out some of my jams!</h3>
      </div>
    </div>
  );

export default Music