import React from 'react'
import './Navbar.css'
import logo from'../media/logo.PNG'
import searchIcon from '../media/searchIcon.png'
import CustomLink from './Utilities/CustomLink'

const Navbar = () => {
  return (
    <div className="navbar">
      <div className="logo">
        <img className="logo" src={logo} alt="Logo" />
      </div>
      <div className="navigation">
        <div className='nav-buttons'>
          <ul>
              <CustomLink className="nav-button" to='/'>Home</CustomLink>
              <CustomLink className="nav-button" to='/projects'>Projects</CustomLink>
              <CustomLink className="nav-button" to='/music'>Music</CustomLink>
              <CustomLink className="nav-button" to='/about'>About</CustomLink>
              <CustomLink className="nav-button" to='/blog'>Blog</CustomLink>
              <CustomLink className="nav-button" to='/contact'>Contact</CustomLink>
          </ul>
        </div>
        {/* <div className="search-bar">
            <input type="text" placeholder="Search" />
            <button className="search-button"><img className="search-icon" src={searchIcon} alt="?" /></button>
        </div> */}
      </div>
    </div>
  );
};

export default Navbar;