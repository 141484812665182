import React from 'react'
import ProjectItem from './Containers/ProjectItem'
import Seal from '../media/elephantSeal.webp'
import evcc from '../media/coll101/evccLogo.png'
import ClassProject from './ClassProject.js'
import {useState} from 'react'
// import lastButton from '../media/backButton.png'
// import nextButton from '../media/forwardButton.png'
import backArrow from '../media/backArrow.png'
import './About.css'
import './Projects.css'

//<a href="https://www.freepik.com/search?format=search&last_filter=query&last_value=back&query=back&shape=outline&type=icon">Icon by Soodesign</a>
// Image by <a href="https://www.freepik.com/free-vector/set-arrows-mark-realistic-style_2093764.htm#query=back%20icon&position=5&from_view=search&track=ais">Freepik</a>

const ProjectPanel = ({back}) => (
  <>
    <div className='back-button' onClick={back} style={{display:"flex", alignItems:'center', gap:'.5em', marginBottom:'2em'}}>
      <img style={{height:'1em', width:'1em'}} src={backArrow} alt='""'></img>
      <h3 style={{margin:'0px'}}>Back to Projects</h3>
    </div>
    <div className={'project-panel'}>
      {/* <div className={"last-column"}><img style={{maxHeight:'4em', maxWidth:'4em'}} src={lastButton} alt={'back'}></img> </div> */}
      <div>
        {<ClassProject/>}
      </div>
      {/* <div className={'next-column'}><img style={{maxHeight:'4em', maxWidth:'4em'}} src={nextButton} alt={'next'}></img></div> */}
    </div>
  </>
)

const ProjectList = ({test}) => (
    <>
      {/* <h3>
        I'm into music and technology and I often combine the two.
      </h3> */}
      <div className='project-list'>
        <ProjectItem click={test} profilePic={evcc} title={'Class Project'} date={'11/5/23'} description={'A desciption of the time management and organizational skills I\'ve learned, presented for COLL101 at Everett Community College.'}/>
        {/* <ProjectItem profilePic={Seal} title={'Seal Project'} date={'11/2/23'} description={'this seal is rad'}/> */}
      </div>
    </>
)

const test = () => {
  console.log('click')
}
 
function Projects() {

  const [showProjectList, setShowProjectList] = useState (true)

  return (
    <div className='page-content' >
      <h1 className='title'>Projects</h1>
      <div className='content'>
        {showProjectList ? <ProjectList test={() => setShowProjectList(false)}/> : <ProjectPanel back={()=>setShowProjectList(true)}/>}
        {/* {projectList} */}
      </div>
    </div>
  )
}

export default Projects