import React from 'react'
import { Link , useMatch, useResolvedPath } from 'react-router-dom';
import '../Menu.css';


function CustomLink ({to, children, clicked, ...props}){
    const resolvedPath = useResolvedPath(to)
    const isActive = useMatch({path: resolvedPath.pathname, end: true})
    return (
      <li className={isActive ? "active" : ""} onClick={clicked}>
        <Link to={to} {...props}> {children} </Link>
      </li>
    );
  }

export default CustomLink